import { type ReactNode } from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

interface FeaturesGridProps {
    children: ReactNode
}

export const FeaturesGrid = ({ children }: FeaturesGridProps) => {
    return (
        <div className="w-full">
            <div className="max-w-4xl mx-auto">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2 }}
                >
                    <Masonry gutter="24px">
                        {children}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </div>
    )
}