import { type LinksFunction, type MetaFunction } from '@remix-run/node'
import { useLocation, useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '#app/components/ui/accordion.tsx';
import { AppLogoDark } from '#app/components/ui/app/app-logo-dark'
import { useCustomNavigate } from '#app/components/ui/app/use-custom-navigate.tsx';
import { Button } from '#app/components/ui/catalyst/button.tsx'
import { NoteForm } from '#app/components/ui/note/note-form.tsx';
import { FeaturesGrid } from '../_app+/features-grid.client';

export const meta: MetaFunction = () => [
	{
		title: 'Boring Notes',
	},
	{
		name: 'description',
		content: 'Inspire Your Thoughts, Organize Your World.',
	},
	{
		name: 'og:title',
		content: 'Boring Notes',
	},
	{
		name: 'og:description',
		content: 'Inspire Your Thoughts, Organize Your World.',
	},
	{
		name: 'og:image',
		content: 'https://boringnotes-app.b-cdn.net/boringnotes_hero_desktop.webp',
	}
]

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		// { rel: 'preload', href: 'https://boringnotes-app.b-cdn.net/boringnotes_hero.avif', as: 'image' },
		{ rel: 'preload', href: 'https://boringnotes-app.b-cdn.net/boringnotes_hero_desktop.webp', as: 'image' },
	].filter(Boolean)
}

function ScrollToAnchor() {
	const location = useLocation();

	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.slice(1));
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [location]);

	return null;
}

export default function Index() {
	const navigate = useCustomNavigate()

	return (
		<>
			<ScrollToAnchor />
			<div>
				<main className='px-6 max-w-5xl mx-auto'>
					{/* Hero Section */}
					<header className="py-4 md:pt-16 flex flex-col items-center">
						<div className="flex flex-col justify-center items-center w-full">
							<p className='text-center text-foreground-placeholder font-semibold mb-2'>
								Calling all creative minds and list <span className='text-foreground'>❤️</span> lovers
							</p>
							<h1 className="text-3xl md:text-4xl text-center font-bold mb-4 flex flex-col gap-1">
								<span>Inspire Your Thoughts</span>
								<span className="text-nowrap bg-gradient-to-r from-pink-600 to-indigo-500 bg-clip-text text-transparent">
									Organize Your World.
								</span>
							</h1>
							<div className="text-lg text-center w-fit text-zinc-700">
								From travel plans and favorite eats to product wishlists and project notes,<br />
								<i><strong>turn chaos into clarity</strong></i> with Boring Notes.<br />
								The <i><strong>visual organization tool</strong></i> that <i><strong>adapts to your unique style</strong></i>.
							</div>
							<CTAButton className='mt-6 md:mt-8' />
							<div className='mx-auto w-full mt-16'>
								<Hero />
							</div>
						</div>
					</header>

					{/* Social Proof */}
					{/* <section className="py-12">
					<div className="container mx-auto px-6">
						<h2 className="text-2xl font-bold text-center mb-8">Trusted by Note-Takers Worldwide</h2>
						<div className="flex justify-center space-x-8">
							<div className="w-20 h-20 bg-black/25 rounded-full"></div>
							<div className="w-20 h-20 bg-black/25 rounded-full"></div>
							<div className="w-20 h-20 bg-black/25 rounded-full"></div>
							<div className="w-20 h-20 bg-black/25 rounded-full"></div>
						</div>
					</div>
				</section> */}

					{/* Benefits */}
					<section className="py-16">
						<div className="mx-auto">
							<h2 id='features' className="text-3xl font-bold text-center mb-12">Why Boring Notes?</h2>

							<ClientOnly>{() => {
								return <FeaturesGrid>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/intuitive_organization_v2.webp'
										alt='Intuitive Organization'
										title='Intuitive Organization'
										subtext='Transform scattered thoughts into visually appealing, easy-to-navigate lists.'
									/>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/flexible_tagging_v2.webp'
										alt='Flexible Tagging'
										title='Flexible Tagging'
										subtext='Categorize your ideas effortlessly and find them in seconds.'
									/>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/seamless_sharing_v2.webp'
										alt='Seamless Sharing'
										title='Seamless Sharing'
										subtext='Share your curated lists with the world with a single click.'
									/>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/visual_inspiration_v2.webp'
										alt='Visual Inspiration'
										title='Visual Inspiration'
										subtext='Create mood boards, product collections, or travel plans with a mix of text and images.'
									/>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/cross_platform_v2.webp'
										alt='Cross-Platform'
										title='Cross-Platform'
										subtext='Access your lists and notes from anywhere, on any device.'
									/>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/powerful_editor_v2.webp'
										alt='Powerful Editor'
										title='Powerful Editor'
										subtext='Express your ideas with precision using our feature-rich editor.'
									/>
									<FeatureCard
										image='https://boringnotes-app.b-cdn.net/lightning_fast_search_v2.webp'
										alt='Lightning Fast Search'
										title='Lightning Fast Search'
										subtext='Instantly locate any note with our powerful search function. Never lose an idea again.'
									/>
								</FeaturesGrid>
							}}</ClientOnly>
						</div>
					</section>

					{/* Try It */}
					<section className="py-16">
						<div className="mx-auto">
							<div className='flex flex-col mb-12 items-center gap-1'>
								<div>
									<h2 className="text-3xl font-bold text-center">Try It!</h2>
									<h3 className="text-2xl font-semibold text-center">Create and Share a List, Starting with a Note</h3>
								</div>
								<p className='text-foreground-placeholder text-center font-medium'>
									No sign up is required <span className='text-foreground text-lg'>✌️</span>
								</p>
							</div>
							<div className='max-w-xl mx-auto p-3 ring-4 ring-border rounded-xl bg-white/75'>
								<NoteForm
									key='landing-page'
									autofocus={false}
									onNoteAdded={(note) => {
										const listId = note.lists[0]?.id
										if (listId) {
											navigate(`/list/${listId}`)
										} else {
											navigate(`/note/${note.id}`)
										}
									}}
								/>
							</div>
						</div>
					</section>

					{/* How it Works */}
					<section className="py-16">
						<div className="mx-auto max-w-md">
							<h2 className="text-3xl font-bold text-center mb-12">How to Use Boring Notes</h2>
							<div className="max-w-2xl mx-auto">
								<ol className="list-decimal list-inside space-y-2 text-lg px-3">
									<li>Sign up for your free account</li>
									<li>Create a list</li>
									<li>Add a note to the list</li>
									<li>Add tags, photos and links to the note</li>
									<li>Repeat 3 and 4</li>
									<li>(Optional) Share the list</li>
								</ol>
								<p className='mt-4 text-lg px-3'>
									That's it! Create as many lists as you need!
								</p>
							</div>
						</div>
					</section>

					{/* Testimonials */}
					{/* <section className="py-16">
					<div className="container mx-auto px-6">
						<h2 className="text-3xl font-bold text-center mb-12">What Our Users Say</h2>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="bg-note-card-background p-6 rounded-lg ring-1 ring-black/10">
								<p className="mb-4">"Boring Notes has completely transformed how I organize my thoughts. It's simple yet powerful!"</p>
								<p className="font-semibold">- Jane Doe, Writer</p>
							</div>
							<div className="bg-note-card-background p-6 rounded-lg ring-1 ring-black/10">
								<p className="mb-4">"The tagging system is a game-changer. I can finally keep track of all my ideas effortlessly."</p>
								<p className="font-semibold">- John Smith, Entrepreneur</p>
							</div>
						</div>
					</div>
				</section> */}

					{/* FAQ */}
					<section className="py-16">
						<div className="mx-auto">
							<h2 id='faqs' className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
							<Accordion type='single' collapsible className='max-w-2xl mx-auto'>
								<AccordionItem value="item-1" className='!border-b-border'>
									<AccordionTrigger>
										<h3 className="text-xl font-semibold text-start">
											Is Boring Notes free to use?
										</h3>
									</AccordionTrigger>
									<AccordionContent>
										<p className='text-base'>
											Yes, Boring Notes offers a free tier that lets you fully explore its features.
											This allows you to experience the app before committing. Consider our affordable
											paid plans for continued use (coming soon).
										</p>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-2" className='!border-b-border'>
									<AccordionTrigger>
										<h3 className="text-xl font-semibold text-start">
											Why is Boring Notes not completely free? Why should I pay?
										</h3>
									</AccordionTrigger>
									<AccordionContent>
										<p className='text-base'>
											Boring Notes was born from the passion and search for a tool that empowers users to
											organize and share ideas effortlessly.
										</p>
										<p className='mt-3 text-base'>
											We are not funded. We don't run ads, sell data, or track users.
										</p>
										<p className='mt-3 text-base'>
											Only through the support of our generous members are we able to maintain and improve this platform.
										</p>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-3" className='!border-b-border'>
									<AccordionTrigger>
										<h3 className="text-xl font-semibold text-start">
											Can I access my notes on multiple devices?
										</h3>
									</AccordionTrigger>
									<AccordionContent>
										<p className='text-base'>
											Absolutely! Access Boring Notes from any internet-connected device.
											For the full, rich note-taking experience, we've specially crafted our interface for desktop use.
										</p>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-4" className='!border-b-border'>
									<AccordionTrigger>
										<h3 className="text-xl font-semibold text-start">
											Does Boring Notes use AI?
										</h3>
									</AccordionTrigger>
									<AccordionContent>
										<p className='text-base'>
											Not currently, but we're exploring AI powered features for future enhancements.
										</p>
										<p className='text-base mt-3'>
											<span>Have an ideas? </span>
											<a href='https://tally.so/r/w4v05X' target='_blank' className='font-medium underline'>Share with us</a>.
										</p>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-5" className='!border-b-border'>
									<AccordionTrigger>
										<h3 className="text-xl font-semibold text-start">
											Why do you call it Boring Notes?
										</h3>
									</AccordionTrigger>
									<AccordionContent>
										<p className='text-base'>
											We like the name; it's easy to remember.
											It's also a playful take on perception that organizing thoughts/ideas is a boring and mundane task.
										</p>
										<p className='mt-4 text-base'>
											We want to make it an easy and fun (less boring),
											something that would encourage us to write more.
										</p>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</div>
					</section>

					{/* Pricing */}
					<section className='py-16'>
						<div className="mx-auto">
							<h2 id='pricing' className="text-3xl font-bold text-center mb-12">Pricing</h2>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div className="bg-white p-6 rounded-lg ring-1 ring-black/10">
									<p className='text-foreground-placeholder text-sm'>Free</p>
									<h2 className='text-lg font-semibold mb-4'>$0/month</h2>
									<ul className='list-disc list-inside space-y-1'>
										<li><span className='line-through'>Create up to 10 lists</span></li>
										<li><span className='line-through'>Create up to 100 notes, images or links</span></li>
										<li><strong>Unlimited lists, notes, images, and links during beta</strong></li>
										<li>Unlimited tags</li>
										<li>Instant notes search</li>
										<li>Lists and notes sharing</li>
									</ul>
								</div>
								<div className="bg-white p-6 rounded-lg ring-2 ring-green-700">
									<p className='text-foreground-placeholder text-sm'>Unlimited</p>
									<h2 className='text-lg font-semibold mb-4'>Coming Soon</h2>
									<p className='mb-2 font-semibold'>We are currently in beta! Join now to:</p>
									<ul className='list-disc list-inside space-y-1'>
										<li>Get unlimited notes</li>
										<li>Help shape our product</li>
										<li>Get preferential pricing when we launch!</li>
									</ul>
									<p className='mt-4 font-medium'>Expected: $4.99 - $9.99 /month</p>
									<p className='text-sm text-foreground-placeholder'>Final pricing to be determined</p>
								</div>
							</div>
							<div className='mt-3 text-sm px-3 text-foreground-placeholder'>
								<strong>Beta phase:</strong> We're currently offering unlimited usage to all users as
								we refine our product. Early adopters will be eligible for exclusive discounts when we
								launch paid plans. Stay tuned for updates!
							</div>
							<div className='mt-3 text-sm px-3 text-foreground-placeholder'>
								<strong>Note on fair usage:</strong> While we offer unlimited features, we expect all usage
								to be reasonable and for personal use. We reserve the right to limit or suspend accounts
								that show signs of abuse that may negatively impact our service or other user's experience.
							</div>
						</div>
					</section>

					{/* Final CTA */}
					<section className="py-16 flex flex-col items-center justify-center">
						<div className="mx-auto text-center">
							<h2 className="text-3xl font-bold mb-4">Try Boring Notes for free today</h2>
							<p className="text-xl mb-8">
								Experience a new way of organizing and sharing your ideas with Boring Notes.
							</p>
						</div>
						<CTAButton />
					</section>
				</main>

				{/* Footer */}
				<footer className="bg-black text-white py-8 px-6">
					<div className="container mx-auto px-6 max-w-4xl">
						<div className='flex flex-col gap-2'>
							<AppLogoDark />
							<p>
								Inspire Your Thoughts, Organize Your World
							</p>
						</div>

						<div className="h-px bg-white/20 my-8" />

						<div className="flex flex-wrap justify-between">
							<div className="w-full md:w-1/2 mb-6 md:mb-0">
								<h3 className="text-lg font-semibold mb-2">Quick Links</h3>
								<ul className="space-y-2">
									<li><a href="#features" className="hover:text-gray-300">Features</a></li>
									<li><a href="#pricing" className="hover:text-gray-300">Pricing</a></li>
									<li><a href="#faqs" className="hover:text-gray-300">FAQs</a></li>
									{/* <li><a href="/blogs/all" className="hover:text-gray-300">Blog</a></li> */}
								</ul>
							</div>
							<div className="w-full md:w-1/2 mb-6 md:mb-0">
								<h3 className="text-lg font-semibold mb-2">Legal</h3>
								<ul className="space-y-2">
									<li><a href="/privacy" className="hover:text-gray-300">Privacy Policy</a></li>
									<li><a href="/terms" className="hover:text-gray-300">Terms of Service</a></li>
									{/* <li><a href="/refund" className="hover:text-gray-300">Refund Policy</a></li> */}
								</ul>
							</div>
						</div>
						<div className="mt-8 text-center">
							<p>&copy; 2024 Boring Notes. All rights reserved.</p>
						</div>
					</div>
				</footer>
			</div>
		</>
	)
}

const CTAButton = ({ className }: { className?: string }) => {
	const navigate = useNavigate()
	return (
		<Button
			color='orange' className={`!px-6 !py-2 text-lg ${className ?? ''}`}
			onClick={() => navigate('/h')}
		>
			<div className='flex items-center gap-x-2 whitespace-nowrap'>
				Start Organizing
				<div className='text-white/85 text-lg font-normal'>- it's free</div>
			</div>
		</Button>
	)
}

const FeatureCard = ({ image, alt, title, subtext }: { image: string, alt: string, title: string, subtext: string }) => {
	return (
		<div className="bg-note-card-background rounded-3xl overflow-hidden ring-2 ring-[#FFB866]">
			<img src={image} alt={alt} loading='lazy' />
			<div className='p-4'>
				<h3 className="text-xl font-mono font-semibold mb-2">
					{title}
				</h3>
				<p>
					{subtext}
				</p>
			</div>
		</div>
	)
}

const Hero = ({ className }: { className?: string }) => {
	return (
		<>
			<div className={`ring-2 ring-black/25 rounded-lg overflow-hidden ${className ?? ''} hidden sm:block`}>
				<img
					src='https://boringnotes-app.b-cdn.net/boringnotes_hero_desktop.webp'
					alt='Boring Notes Hero'
					className="h-full w-full rounded-md object-cover hidden sm:block"
					loading='lazy'
				/>
			</div>
			<img
				src='https://boringnotes-app.b-cdn.net/boringnotes_hero_mobile.webp'
				alt='Boring Notes Hero'
				className="h-full w-full rounded-md object-cover sm:hidden"
				loading='lazy'
			/>
		</>
	)
}